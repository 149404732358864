import {ChangeDetectorRef, Component, Inject, PLATFORM_ID} from '@angular/core';
import {DomSanitizer, SafeHtml, SafeStyle} from '@angular/platform-browser';
import {SponsorshipService} from '../../../../shared/services/sponsorship.service';
import {environment} from '../../../../../environments/environment';
import {Router} from '@angular/router';
import {SponsorService} from '../../../../shared/services/sponsor.service';
import {CacheService, SponsorshipContent, SponsorshipSettings} from '@isifid/core';
import {isPlatformBrowser} from '@angular/common';

@Component({
    selector: 'app-sponsor-explanations',
    templateUrl: './explanations.component.html',
    styleUrls: ['./explanations.component.scss'],
    standalone: false
})
export class ExplanationsComponent {
    legalHomepage: SafeHtml;
    explanationsAdvantages1: SafeHtml;
    explanationsAdvantages2: SafeHtml;
    explanationsAdvantages3: SafeHtml;
    explanationsAdvantages4: SafeHtml;
    content: SponsorshipContent;
    slides: Array<any> = [];
    private settings: SponsorshipSettings;


    constructor(
        private readonly sponsorshipService: SponsorshipService,
        private readonly sanitizer: DomSanitizer,
        private readonly router: Router,
        private readonly sponsorService: SponsorService,
        private readonly cacheService: CacheService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        @Inject(PLATFORM_ID) private platformId
    ) {
        this.content = sponsorshipService.getContent();
        this.settings = sponsorshipService.getSettings();
        this.explanationsAdvantages1 = this.sanitizer.bypassSecurityTrustHtml(this.content.explanationsAdvantages1);
        this.explanationsAdvantages2 = this.sanitizer.bypassSecurityTrustHtml(this.content.explanationsAdvantages2);
        this.explanationsAdvantages3 = this.sanitizer.bypassSecurityTrustHtml(this.content.explanationsAdvantages3);
        this.explanationsAdvantages4 = this.sanitizer.bypassSecurityTrustHtml(this.content.explanationsAdvantages4);
        this.legalHomepage = this.sanitizer.bypassSecurityTrustHtml(this.content.legalNoticeSpecificHomepage);
        this.slides.push({
            explanationsAdvantages: this.explanationsAdvantages1,
            imgUrl: `${environment.cdnUrl}/clients/${this.settings.templateHash}/0-welcome.jpg`
        });
        this.slides.push({
            explanationsAdvantages: this.explanationsAdvantages2,
            imgUrl: `${environment.cdnUrl}/clients/${this.settings.templateHash}/1-benefits.jpg`
        });
        this.slides.push({
            explanationsAdvantages: this.explanationsAdvantages3,
            imgUrl: `${environment.cdnUrl}/clients/${this.settings.templateHash}/2-gifts.jpg`
        });
        this.slides.push({
            explanationsAdvantages: this.explanationsAdvantages4,
            imgUrl: `${environment.cdnUrl}/clients/${this.settings.templateHash}/3-online.jpg`
        });
        changeDetectorRef.markForCheck();
    }

    explanationsBackgroundImage(): SafeStyle {
        return this.sanitizer.bypassSecurityTrustStyle(`url('${environment.cdnUrl}/clients/${this.settings.templateHash}/explanation-banner.jpg')`);
    }

    explanationsBackgroundImageMobile(): SafeStyle {
        // 768px bootstrap md
        if (isPlatformBrowser(this.platformId) && window.screen.width < 768) {
            return this.sanitizer.bypassSecurityTrustStyle(`linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
            url('${environment.cdnUrl}/clients/${this.settings.templateHash}/explanation-banner.jpg')`);
        } else return null;
    }

    advantagesBackgroundImage(name: string): SafeStyle {
        return this.sanitizer.bypassSecurityTrustStyle(`url('${environment.cdnUrl}/clients/${this.settings.templateHash}/${name}')`);
    }

    referralCreation(): void {
        if (this.sponsorService.sponsorshipUser) this.router.navigate(['/parrain/inviter']).then();
        else if (this.cacheService.getContent('consumer')) this.router.navigate(['/parrain/compte/validation']).then();
        else this.router.navigate(['/parrain/compte/creation']).then();
    }
}
