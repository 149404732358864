import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject, Observable, skip} from 'rxjs';
import {AuthenticationService, MsServicesSponsorshipService, TrackingService} from '@isifid/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {SponsorshipService} from './sponsorship.service';
import {SponsorService} from './sponsor.service';
import {environment} from '../../../environments/environment';
import {Meta} from '@angular/platform-browser';

@Injectable({providedIn: 'root'})
export class BootstrapService {
    public isInitialised: BehaviorSubject<boolean>;
    private settingsId: number;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly msServicesSponsorshipService: MsServicesSponsorshipService,
        private readonly sponsorshipService: SponsorshipService,
        private readonly sponsorService: SponsorService,
        private readonly trackingService: TrackingService,
        private meta: Meta,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private readonly platformId
    ) {
        this.isInitialised = new BehaviorSubject<boolean>(false);

        this.initEntitiesFromCache();

        // Init the app if we have an ongoing auth or we're in demo mode
        const hash = this.document.defaultView.location.pathname.split('/');
        if (hash?.[1] === 'c' || hash?.[1] === 'auth') this.authenticationService.logout(false, false);
        if (this.authenticationService.isAuth.value) this.init();

        // We skip the first value as it's emitted when we init the app
        // We deal with the first value above
        this.authenticationService.isAuth.pipe(skip(1)).subscribe({
            next: (isAuth) => {
                // Destroy the init if it was initialized but auth has been destroyed
                if (!isAuth && this.isInitialised.value) this.destroy();
            }
        });
    }

    private static doesFileExist(urlToFile) {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('HEAD', urlToFile, false);
            xhr.send();
            return xhr.status !== 404;
        } catch {
            return false;
        }
    }

    initEntitiesFromCache() {
        this.sponsorshipService.initEntitiesFromCache();
        this.sponsorService.initEntitiesFromCache();
    }

    authenticateByPurlToken(purl: string): Observable<any> {
        this.sponsorshipService.setMode('sponsor');

        return new Observable(observer => {
            this.authenticationService.loginByConsumerToken(
                this.authenticationService.getPurlTokenFromConcealedPurlToken(purl) ?? purl
            ).subscribe({
                next: (tokens) => {
                    const consumerId = JSON.parse(window.atob(tokens.accessToken.split('.')[1])).consumerId;

                    this.msServicesSponsorshipService.getSettingsByClientId().subscribe({
                        next: (settings) => {
                            this.settingsId = settings.id;
                            this.initByConsumer(consumerId, observer);
                        }
                    });
                }, error: (error) => observer.error(error)
            });
        });
    }

    authenticateBySponsorCode(sponsorCode: string): Observable<any> {
        this.sponsorshipService.setMode('sponsored');
        return new Observable(observer => {
            // Get data from autologin to log sponsored
            this.msServicesSponsorshipService.getAutologin(sponsorCode).subscribe({
                next: (settings) => {
                    this.settingsId = settings.id;
                    this.authenticationService.loginByEmailPassword(settings.loginEmail, settings.loginPassword).subscribe({
                        next: () => {
                            this.initByCode(sponsorCode, observer);
                        }, error: (error) => observer.error(error)
                    });
                },
                error: (error) => observer.error(error)
            });
        });
    }

    authenticateByHash(hash: string): Observable<any> {
        this.sponsorshipService.setMode('public');

        let hashData;
        try {
            hashData = window.atob(hash).split(',');
            hashData[2] = Number(hashData[2]);
            // We need 3 args and the 3rd must be a number
            if (hashData.length < 3 || Number.isNaN(hashData[2])) hashData = null;
        } catch {
            hashData = null;
        }
        // Bad hash
        if (!hashData) return new Observable(observer => observer.error('Bad hash'));

        this.settingsId = Number(hashData[2]);

        return new Observable(observer => {
            this.authenticationService.loginByEmailPassword(hashData[0], hashData[1]).subscribe({
                next: () => {
                    this.init();
                    observer.next();
                    observer.complete();
                }, error: (error) => observer.error(error)
            });
        });
    }

    authenticateFromGift(token: string, consumerId: string, settingsId: string): Observable<any> {
        this.settingsId = Number(settingsId);
        this.sponsorshipService.setMode('advisor');

        return new Observable<any>(observer => {
            this.authenticationService.loginBySso('isifid', token).subscribe({
                next: () => {
                    this.sponsorService.initByConsumerId(consumerId).subscribe({
                        next: () => {
                            this.init();
                            observer.next();
                            observer.complete();
                        },
                        error: (error) => observer.error(error)
                    });
                }, error: (err) => observer.error(err)
            });
        });
    }

    private init() {
        if (!isPlatformBrowser(this.platformId)) return;

        const token = this.authenticationService.getRefreshToken();
        if (!token) this.authenticationService.logout(true, false);
        
        const clientId = JSON.parse(window.atob(token.split('.')[1])).clientId;
        // Logout if we don't have enough info
        if (!clientId) this.authenticationService.logout(true, false);

        if (!this.settingsId) {
            // In some case when pressing back on the browser, the user can have lost the settingsId
            // So the only option is to reload the app
            if (this.sponsorshipService.getSettings()?.id) this.settingsId = this.sponsorshipService.getSettings().id;
            else window.location.reload();
        }

        this.sponsorshipService.initContentAndSettings(this.settingsId).subscribe({
            next: () => {
                let urn = '';
                if (this.sponsorService.sponsorshipUser) {
                    urn = '/c/' + (this.sponsorService.sponsorshipUser.codeCustomised || this.sponsorService.sponsorshipUser.code);
                }
                this.initClientDOMAttributes(urn);
                this.trackingService.initMatomo(this.sponsorshipService.settings.matomoSiteId).subscribe();
                this.isInitialised.next(true);
            }, error: (error) => {
                console.error('init error: ' + error);
                this.authenticationService.logout(true, false);
            }
        });
    }

    private initClientDOMAttributes(urn?: string): void {
        const settings = this.sponsorshipService.getSettings();
        const content = this.sponsorshipService.getContent();

        if (urn) this.meta.updateTag({name: 'og-url', content: settings.domain + urn});

        // Update meta
        this.meta.updateTag({
            name: 'og-title',
            content: content.ogTitle
        });
        this.meta.updateTag({
            name: 'og-image',
            content: environment.cdnUrl + '/clients/' + settings.templateHash + '/social-share-og.jpg'
        });
        this.meta.updateTag({
            name: 'og-description',
            content: content.ogDescription
        });

        // Avoid SSR errors
        if (document.getElementById('favicon') !== null) {
            document.getElementById('favicon').setAttribute('href', environment.cdnUrl + '/common/img/favicon/' + settings.networkName + '.png');
        }
        if (document.getElementById('network_css') !== null) {
            document.getElementById('network_css').setAttribute('href', 'assets/css/network/' + settings.networkName.toLowerCase() + '.css');
        }
        if (document.getElementById('custom_css') !== null) {
            const customCss = 'assets/css/custom/' + settings.templateHash.toUpperCase() + '.css';
            if (BootstrapService.doesFileExist(customCss)) document.getElementById('custom_css').setAttribute('href', customCss);
            else document.getElementById('custom_css').removeAttribute('href');
        }
    }
    
    private initByCode(sponsorCode, observer) {
        this.sponsorService.initBySponsorCode(sponsorCode).subscribe({
            next: () => {
                this.init();
                observer.next();
                observer.complete();
            }, error: (error) => observer.error(error)
        });
    }

    private initByConsumer(consumerId, observer) {
        this.sponsorService.initByConsumerId(consumerId).subscribe({
            next: () => {
                this.init();
                observer.next();
                observer.complete();
            }, error: (error) => observer.error(error)
        });
    }

    private destroy() {
        this.sponsorshipService.destroy();
        this.sponsorService.destroy();
        this.isInitialised.next(false);
    }
}
