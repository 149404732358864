import {ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SponsorService} from '../../../shared/services/sponsor.service';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {SponsorshipService} from '../../../shared/services/sponsorship.service';
import {TrackingServiceSponsorship} from '../../../shared/services/tracking-service-sponsorship.service';
import {AuthenticationService, authGuardFn, CacheService, trackGuardFn, TrackingService} from '@isifid/core';
import {BootstrapService} from '../../../shared/services/bootstrap.service';
import {CEHDF0059038FD37B4AComponent} from '../../sponsor/home/CEHDF0059038FD37B4A/CEHDF0059038FD37B4A.component';
import {CACEE9528800D4243DDComponent} from '../../sponsor/home/CACEE9528800D4243DD/CACEE9528800D4243DD.component';

@Component({
    selector: 'app-root',
    templateUrl: './auth.component.html',
    standalone: false
})
export class AuthComponent implements OnInit {
    loading: boolean;
    private customRoute: string;
    private hash: string;
    private sponsorCode: string;
    private provider: string;
    private token: string;
    private consumerId: string;
    private giftUserId: string;
    private settingsId: string;
    private emailUnSubscribing: boolean;

    constructor(
        private authenticationService: AuthenticationService,
        private readonly bootstrapService: BootstrapService,
        private cacheService: CacheService,
        private route: ActivatedRoute,
        private router: Router,
        private sponsorshipService: SponsorshipService,
        private sponsorService: SponsorService,
        private trackingServiceSponsorship: TrackingServiceSponsorship,
        private trackingService: TrackingService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        @Inject(PLATFORM_ID) private platformId,
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.changeDetectorRef.markForCheck();
        this.authenticationService.logout(false, false);
        this.parseUrl();
        this.dispatch();
    }

    // Public pages: hash (paramMap)
    // Advisor sharing its link: hash (paramMap) + advisorId (urlParams)
    // Sponsor sharing its referral link: sponsorCode (in paramMap)
    // Consumer coming from marketplace: token (urlParams)
    // Advisor coming from Gift: token, consumerId, settingsId (urlParams)
    // Route allow to redirect on a specific URL
    private parseUrl(): void {
        if (isPlatformBrowser(this.platformId)) {
            const urlParams = new URLSearchParams(this.document.defaultView.location.search);
            this.token = urlParams.get('token');
            this.consumerId = urlParams.get('consumerId');
            this.settingsId = urlParams.get('settingsId');
            this.giftUserId = urlParams.get('giftUserId');
            this.provider = urlParams.get('provider');
            this.emailUnSubscribing = urlParams.get('action') === 'email-unsubscribe';

            if (this.giftUserId) this.cacheService.addPermanentContent('giftUserId', this.giftUserId);

            const branchCode = urlParams.get('branchCode');
            if (branchCode) this.cacheService.addPermanentContent('branchCode', branchCode);


            this.customRoute = urlParams.get('route') ? decodeURIComponent(urlParams.get('route')) : null;
            this.trackingServiceSponsorship.storeUtmParams(this.route.queryParams);
        }

        // These params can be accessed by anything, even if platform browser is not defined
        this.hash = this.route.snapshot.paramMap.get('hash');
        this.sponsorCode = this.route.snapshot.paramMap.get('sponsorCode');

        if (!this.provider) {
            if (this.token) this.provider = 'purl';
            else if (this.hash) this.provider = 'hash';
            else if (this.sponsorCode) this.provider = 'sponsorCode';
        }
        this.changeDetectorRef.markForCheck();
    }

    // Dispatch among several ways to connect
    private dispatch(): void {
        switch (this.provider) {
        case 'gift':
            this.authenticateFromGift();
            break;
        case 'purl':
            this.authenticationByPurlToken();
            break;
        case 'hash':
            this.authenticationByHash();
            break;
        case 'sponsorCode':
            this.authenticationBySponsorCode();
            break;
        default:
            this.router.navigateByUrl('/logout').then();
            break;
        }
    }

    // Default authentication to access public pages
    private authenticationByHash(): void {
        // We need a hash
        if (!this.hash) {
            this.router.navigateByUrl('/logout').then();
            return;
        }
        if (!isPlatformBrowser(this.platformId)) return;

        this.bootstrapService.authenticateByHash(this.hash).subscribe({
            next: () => this.initAfterAuth(false),
            error: (error) => this.sessionExpired(error)
        });
    }

    // Authenticate sponsored coming
    private authenticationBySponsorCode(): void {
        // Must be enabled for social networks
        // Don't stop loading & no redirect if SSO
        if (!isPlatformBrowser(this.platformId)) return;

        // If already authenticated & sponsorCode is the same as the one used
        if (this.authenticationService.isAuthenticated() && this.sponsorService.getSponsorCode() === this.sponsorCode) {
            this.initAfterAuth(false, '/filleul/accueil');
        } else {
            this.bootstrapService.authenticateBySponsorCode(this.sponsorCode).subscribe({
                next: () => this.initAfterAuth(false, '/filleul/accueil'),
                error: (error) => this.sessionExpired(error)
            });
        }
    }

    // Authentication from marketplace or email via auth?token=XXX
    private authenticationByPurlToken(): void {
        this.bootstrapService.authenticateByPurlToken(this.token).subscribe({
            next: () => this.initAfterAuth(true),
            error: (error) => this.sessionExpired(error)
        });
    }

    // Authentication from Gift with token / consumerId / provider
    private authenticateFromGift(): void {
        this.bootstrapService.authenticateFromGift(this.token, this.consumerId, this.settingsId).subscribe({
            next: () => this.initAfterAuth(true),
            error: (error) => this.sessionExpired(error)
        });
    }

    private sessionExpired(error: any): void {
        console.error(error);
        this.authenticationService.logout(false, false);
        this.router.navigate(['/logout']).then();
    }

    // Add custom homepage for CE HDF only
    private useCustomSponsorHP(): void {
        if (this.sponsorshipService.customExplanationsPage) {
            // Remove explanations routes & add it again as it has been previously set up
            const i = this.router.config.findIndex(x => x.path === 'parrain/accueil');
            this.router.config.splice(i, 1);
            const customHP =
            this.sponsorshipService.settings.templateHash === 'CACEE9528800D4243DD' ?
                {
                    path: 'parrain/accueil',
                    canActivate: [trackGuardFn, authGuardFn],
                    component: CACEE9528800D4243DDComponent
                } : {
                    path: 'parrain/accueil',
                    canActivate: [trackGuardFn, authGuardFn],
                    component: CEHDF0059038FD37B4AComponent
                };
            // Must be added at the beginning to avoid being caught by 404 wildcard
            this.router.config.splice(2, 0, customHP);
        }
    }

    private initAfterAuth(isSponsor: boolean, route?: string): void {
        this.bootstrapService.isInitialised.subscribe((isInitialised) => {
            if (!isInitialised) return;

            if (this.giftUserId) {
                this.trackingService.trackEvent('scan-qr-code', 'giftUserId:' + this.giftUserId, '', '1').subscribe();
            }

            // Check if we need to validate the sponsor account
            if (this.emailUnSubscribing && this.sponsorService.sponsorshipUser?.allowCommunications) route = '/parrain/compte/unsubscribe';
            else if (isSponsor) {
                // Set the custom sponsor HP if needed
                // For sponsor, we need to check if the allowCommunications is set (not for advisor)
                if (typeof this.sponsorService.sponsorshipUser.allowCommunications === 'undefined') route = '/parrain/compte/validation';
                else route = '/parrain/inviter';
            } else {
                if (this.customRoute) route = this.customRoute;
                if (!route) route = '/parrain/accueil';
            }

            this.useCustomSponsorHP();
            this.router.navigateByUrl(route).then();
        });
    }
}
