import {ChangeDetectorRef, Component} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {SponsorshipService} from '../../services/sponsorship.service';
import {AuthenticationService, SponsorshipContent, SponsorshipSettings} from '@isifid/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: false
})
export class FooterComponent {
    content: SponsorshipContent;
    settings: SponsorshipSettings;
    logoUrl: string;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly sponsorshipService: SponsorshipService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
        if (this.authenticationService.isAuthenticated()) {
            this.content = this.sponsorshipService.getContent();
            this.settings = this.sponsorshipService.getSettings();
            this.logoUrl = `${environment.cdnUrl}/clients/${this.settings.templateHash}/logo.png`;
            changeDetectorRef.markForCheck();
        }
    }
}
