import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {SponsorshipService} from '../../services/sponsorship.service';
import {SponsorService} from '../../services/sponsor.service';
import {Router} from '@angular/router';
import {AuthenticationService, SponsorshipSettings, SponsorshipUser} from '@isifid/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false
})
export class HeaderComponent {
    logoUrl: string;
    sponsorshipUser: SponsorshipUser;
    sponsorCodeUrl: boolean;
    settings: SponsorshipSettings;

    @Input() links = true;

    constructor(
        private readonly authenticationService: AuthenticationService,
        public readonly sponsorshipService: SponsorshipService,
        private readonly sponsorService: SponsorService,
        private readonly router: Router,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
        if (this.authenticationService.isAuthenticated()) {
            this.settings = this.sponsorshipService.getSettings();
            this.logoUrl = `${environment.cdnUrl}/clients/${this.settings?.templateHash}/logo.png`;
            this.sponsorshipUser = this.sponsorService.sponsorshipUser;
            this.sponsorCodeUrl = this.router.url === '/home' || this.router.url === '/filleul/accueil' ||
                this.router.url.includes('/filleul/rendez-vous') || this.router.url.includes('/parrain/login') ||
                this.router.url === '/parrain/compte/creation';
            this.changeDetectorRef.markForCheck();
        }
    }

    goToHome(): void {
        // If no links for the header, don't move
        if (!this.links) return;
        if (this.router.url.startsWith('/filleul')) this.router.navigate(['/filleul/accueil']).then();
        else this.router.navigate(['/parrain/accueil']).then();
    }
}
