import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Platform} from '@angular/cdk/platform';
import {SponsorService} from '../../../shared/services/sponsor.service';
import {CustomValidator} from '../../../shared/helpers/custom.validator';
import {SponsorshipService} from '../../../shared/services/sponsorship.service';
import {ShareService} from '../../../shared/services/share.service';
import {FormValidators, MobileService, MsServicesSponsorshipService, Sponsored, TrackingService} from '@isifid/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {TrackingServiceSponsorship} from '../../../shared/services/tracking-service-sponsorship.service';

@Component({
    templateUrl: './invite.component.html',
    styleUrls: ['./invite.component.scss'],
    standalone: false
})
export class InviteComponent implements OnInit {
    copyContent = 'Copier le code';
    countSponsored: number;
    inviteForm: FormGroup;
    isAllowedToInvite: boolean;
    legalNoticeSpecificStep2: SafeHtml;
    loadingSendingInvites: boolean;
    sent = false;
    urlToShare: string;
    mobileMailToLink: string = '';
    loading: boolean = true;
    private customError: string;
    private formError: string;
    private nbSponsored: number;
    private sponsoredNumber: number;
    private sponsorshipText: string;

    constructor(
        public readonly platForm: Platform,
        private readonly mobileService: MobileService,
        private readonly formBuilder: FormBuilder,
        private readonly formValidators: FormValidators,
        private readonly msServicesSponsorshipService: MsServicesSponsorshipService,
        private readonly router: Router,
        public readonly sponsorshipService: SponsorshipService,
        private readonly sanitizer: DomSanitizer,
        private readonly shareService: ShareService,
        private readonly sponsorService: SponsorService,
        public readonly trackingService: TrackingService,
        public readonly trackingServiceSponsorship: TrackingServiceSponsorship,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
    }

    get sponsoredList(): any {
        return (this.inviteForm.get('sponsoredList') as FormArray).controls;
    }

    ngOnInit(): void {
        this.urlToShare = `${window.location.origin}/c/${this.sponsorService.sponsorshipUser.codeCustomised || this.sponsorService.sponsorshipUser.code}`;
        this.legalNoticeSpecificStep2 = this.sanitizer.bypassSecurityTrustHtml(this.sponsorshipService.getContent().legalNoticeSpecificStep2);

        this.sponsorshipText = this.sponsorshipService.getContent().sponsoredSponsorshipText.replace(/\\n/g, '\n');
        this.sponsorshipText = this.sponsorshipText
            .replace('${sponsorCode}', this.sponsorService.sponsorshipUser.codeCustomised || this.sponsorService.sponsorshipUser.code);

        // Get number of sponsored and hide form if maxSponsoredPerSponsor >= nbSponsored
        this.checkNumberOfSponsored();

        this.loadingSendingInvites = false;
        this.mobileMailToLink = `mailto:?subject=Je te parraine pour une ouverture de compte ${this.sponsorshipService.getContent().clientName}`;
        this.mobileMailToLink += `&body=${this.sponsorshipText.replace(/\n/g, '%0D%0A')}`;

        this.changeDetectorRef.markForCheck();
    }

    addSponsored(): void {
        const sponsoredListForm = this.inviteForm.controls.sponsoredList as FormArray;
        const sponsoredForm = this.formBuilder.group({
            firstName: ['', [Validators.required, CustomValidator.firstNameLastName]],
            lastName: ['', [Validators.required, CustomValidator.firstNameLastName]],
            email: ['', [Validators.required, Validators.email]],
            mobile: ''
        });

        if (this.sponsorshipService.shareBySms) {
            sponsoredForm.addControl('mobile', new FormControl('', [this.formValidators.isFrenchMobilePhone]));
            sponsoredForm.setControl('email', new FormControl('', [Validators.email]));
        }
        sponsoredListForm.push(sponsoredForm);

        this.changeDetectorRef.markForCheck();
    }

    submit(): void {
        this.loadingSendingInvites = true;
        this.sent = true;
        const sponsoredList: Array<Sponsored> = [];
        const sponsoredFormArray = this.inviteForm.controls.sponsoredList as FormArray;
        const sponsoredFormGroups = sponsoredFormArray.controls as Array<FormGroup>;

        if (!this.isFormValid(sponsoredFormGroups)) {
            this.loadingSendingInvites = false;
            return;
        }

        this.changeDetectorRef.markForCheck();

        sponsoredFormGroups.forEach(value => {
            const sponsored = new Sponsored();
            sponsored.firstName = value.get('firstName').value.trim();
            sponsored.lastName = value.get('lastName').value.trim();
            sponsored.email = value.get('email').value?.trim();
            if (this.sponsorshipService.shareBySms) sponsored.mobile = this.mobileService.formatMobile(value.get('mobile').value.trim());
            if (sponsored.firstName && sponsored.lastName && (sponsored.email || sponsored.mobile)) sponsoredList.push(sponsored);
        });

        this.trackingService.trackEvent('invite', sponsoredList.length + ' invitations sent', '', '',
            this.trackingServiceSponsorship.getClientTrackerId()).subscribe();
        const body = {
            sendBySms: this.sponsorshipService.shareBySms === true,
            recipients: sponsoredList,
            sponsorCode: this.sponsorService.sponsorshipUser.codeCustomised || this.sponsorService.sponsorshipUser.code,
            message: this.inviteForm.get('text').value
        };

        // Invite by email and sms
        this.msServicesSponsorshipService.sendInvites(body).subscribe({
            next: () => this.shareService.storeHasSharedByEmail(),
            complete: () => {
                this.router.navigate(['/parrain/compte/historique']).then();
            }
        });
    }

    isContactApiAvailable(): boolean {
        return this.shareService.canPickContact();
    }

    contactPick(): void {
        if (this.shareService.canPickContact()) {
            this.shareService.contactPick().subscribe((contacts) => {
                if (contacts?.length) {
                    const sponsoredListForm = this.inviteForm.controls.sponsoredList as FormArray;
                    sponsoredListForm.clear();
                    // Name => contacts[i].name || Email => contacts[i].email[0] || Tel => contacts[i].tel
                    if (!this.sponsorshipService.shareBySms) contacts.forEach(contact => this.addContact(contact.name[0], contact.email[0]));
                    else contacts.forEach(contact => this.addContact(contact.name[0], contact.email[0], contact.mobile[0]));
                }
            });
        }
    }

    addContact(name: string, email: string, mobile?: string): void {
        // Split name to firstName and LastName
        const firstName = name.substring(0, name.indexOf(' '));
        const lastName = name.substring(name.indexOf(' ') + 1);
        const sponsoredListForm = this.inviteForm.controls.sponsoredList as FormArray;
        const sponsoredForm = this.formBuilder.group({
            firstName: [firstName || '', [Validators.required, CustomValidator.firstNameLastName]],
            lastName: [lastName || '', [Validators.required, CustomValidator.firstNameLastName]],
            email: [email || '', [Validators.required, Validators.email]],
            mobile: mobile || ''
        });

        if (this.sponsorshipService.shareBySms) {
            if (!mobile) mobile = '';
            sponsoredForm.addControl('mobile', new FormControl(mobile, this.formValidators.isFrenchMobilePhone));
        }
        sponsoredListForm.push(sponsoredForm);

        this.changeDetectorRef.markForCheck();
    }

    isShareApiAvailable(): boolean {
        return this.shareService.canShare();
    }

    shareMobile(): void {
        if (this.shareService.canShare()) {
            this.shareService.share({
                title: '',
                text: this.sponsorshipService.getContent().sponsoredSocialText,
                url: this.urlToShare
            }).subscribe();
        }
    }

    private isFormValid(sponsoredFormGroups: Array<FormGroup>): boolean {
        let valid = true;
        this.formError = null;
        this.countSponsored = 0;
        sponsoredFormGroups.forEach(sponsorForm => {
            const firstNameControl = sponsorForm.get('firstName');
            const lastNameControl = sponsorForm.get('lastName');
            const emailControl = sponsorForm.get('email');
            firstNameControl.updateValueAndValidity();
            lastNameControl.updateValueAndValidity();
            emailControl.updateValueAndValidity();

            if (firstNameControl.value === '' || lastNameControl.value === '' ||
                (!this.sponsorshipService.shareBySms && emailControl.value === '') ||
                (this.sponsorshipService.shareBySms && emailControl.value === '' && sponsorForm.get('mobile').value === '')) {
                this.formError = 'Votre formulaire contient des erreurs';
                return;
            }

            firstNameControl.setErrors(Validators.required(firstNameControl));
            const firstNameFormatError = CustomValidator.firstNameLastName(firstNameControl as FormControl);
            if (firstNameFormatError != null) {
                firstNameControl.markAsDirty();
                firstNameControl.setErrors(firstNameFormatError);
                valid = false;
            }

            lastNameControl.setErrors(Validators.required(lastNameControl));
            const lastNameFormatError = CustomValidator.firstNameLastName(lastNameControl as FormControl);
            if (lastNameFormatError != null) {
                lastNameControl.markAsDirty();
                lastNameControl.setErrors(lastNameFormatError);
                valid = false;
            }

            if (!this.sponsorshipService.shareBySms) {
                emailControl.setErrors(Validators.required(emailControl));
                const emailFormatError = Validators.email(emailControl as FormControl);
                if (emailFormatError != null) {
                    emailControl.markAsDirty();
                    emailControl.setErrors(emailFormatError);
                    valid = false;
                }
            } else {
                const mobileControl = sponsorForm.get('mobile');
                mobileControl.updateValueAndValidity();
                const mobileFormatError = this.formValidators.isFrenchMobilePhone(mobileControl as FormControl);
                if (mobileFormatError != null) {
                    mobileControl.markAsDirty();
                    mobileControl.setErrors(mobileFormatError);
                    valid = false;
                }
            }

            this.countSponsored++;
        });

        if (this.sponsorshipService.explicitSponsorAuthorizationContactSponsor && this.inviteForm.get('rgpd').errors) {
            this.inviteForm.get('rgpd').markAsDirty();
            valid = false;
        }
        if (this.sponsorshipService.shareBySms && this.inviteForm.get('check').errors) {
            this.inviteForm.get('check').markAsDirty();
            valid = false;
        }
        if (!valid) this.formError = 'Votre formulaire contient des erreurs';

        // If form is empty, form is not valid
        this.customError = null;
        if (valid && this.countSponsored === 0) {
            this.customError = 'Veuillez renseigner au moins un filleul';
            return false;
        }

        this.changeDetectorRef.markForCheck();
        return valid;
    }

    private checkNumberOfSponsored() {
        this.loading = true;
        this.changeDetectorRef.markForCheck();
        this.msServicesSponsorshipService.getSponsorshipUserBySponsorConsumerId(this.sponsorService.consumer.id)
            .subscribe({
                next: (sponsored) => this.nbSponsored = sponsored.length,
                error: (error) => {
                    // If status 404, no sponsored
                    if (error.status === 404) {
                        this.nbSponsored = 0;
                        this.isAllowedToInvite = true;
                        this.initInviteForm();
                        this.loading = false;
                    } else console.error('error while getting all sponsored');
                },
                complete: () => {
                    this.isAllowedToInvite = !(this.sponsorshipService.getSettings()?.maxSponsoredPerSponsor &&
                        this.nbSponsored >= this.sponsorshipService.getSettings()?.maxSponsoredPerSponsor);
                    if (this.isAllowedToInvite) this.initInviteForm();
                    this.loading = false;
                    this.changeDetectorRef.markForCheck();
                }
            });
    }

    private initInviteForm() {
        // 768 px tablet
        if (typeof window !== 'undefined' && window.innerWidth > 992) {
            this.sponsoredNumber = 3;
        } else {
            this.sponsoredNumber = 1;
        }

        if (this.sponsorshipService.explicitSponsorAuthorizationContactSponsor) {
            this.inviteForm = this.formBuilder.group({
                sponsoredList: this.formBuilder.array([]),
                text: [this.sponsorshipText, Validators.required],
                rgpd: ['', Validators.required]
            });
        } else {
            this.inviteForm = this.formBuilder.group({
                sponsoredList: this.formBuilder.array([]),
                text: [this.sponsorshipText, Validators.required]
            });
        }

        // Invite by sms
        if (this.sponsorshipService.shareBySms) this.inviteForm.addControl('check', new FormControl('', Validators.required));
        this.changeDetectorRef.markForCheck();
        for (let i = 0; i < this.sponsoredNumber; i++) this.addSponsored();
    }
}
